<template>
  <div
    class="table--inner"
    ref="tableInner"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'TblInner'
}
</script>
